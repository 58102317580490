import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  TextField,
  FormControl,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Select,
  makeStyles,
  MenuItem,
  Menu,
  ButtonGroup,
  Box
} from '@material-ui/core'

import {
  grey
} from '@material-ui/core/colors'

const useStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    backgroundColor: grey[300]
  },
  paper: {
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(4)
  },
  editor: {
    minHeight: theme.spacing(12),
    padding: theme.spacing(2)
  }
}))

const TextStyle = props => {

  return <>
    <Button onClick={() => {
      props.setMenu(!props.menu)
    }}>
      {props.textStyle}
    </Button>
    <Menu
      keepMounted
      open={props.menu}
      onClose={() => { props.setMenu(!props.menu) }}
      anchorEl="heading"
      value="heading">
      <MenuItem value="heading" onClick={() => {
        document.execCommand('fontSize', true, 6)
        props.setMenu(!props.menu)
        props.setTextStyle('Heading')
      }}>Heading</MenuItem>
      <MenuItem value="subtitle" onClick={() => {
        document.execCommand('fontSize', true, 3);
        props.setMenu(!props.menu)
        props.setTextStyle('Subtitle')
      }}>Subtitle</MenuItem>
    </Menu>
  </>
}

TextStyle.propTypes = {
  menu: PropTypes.bool,
  setMenu: PropTypes.func,
  textStyle: PropTypes.string,
  setTextStyle: PropTypes.func
}

const Fontfamily = props => {

  const toogleMenu = () => {
    props.setMenu(!props.menu)
  }

  const fontToggle = font => {
    document.execCommand('fontName', true, font)
    props.setFont(font);
    toogleMenu();
  }

  return <>
    <Button onClick={toogleMenu}>
      {props.font}
    </Button>
    <Menu
      open={props.menu}
      onClose={toogleMenu}
    >
      <MenuItem onClick={() => { fontToggle('Roboto') }}>Roboto</MenuItem>
      <MenuItem onClick={() => { fontToggle('Oswald') }} >Oswald</MenuItem>
      <MenuItem onClick={() => { fontToggle('Ubuntu') }} >Ubuntu</MenuItem>
      <MenuItem onClick={() => { fontToggle('Open Sans') }} >Open Sans</MenuItem>
      <MenuItem onClick={() => { fontToggle('Krona One') }} >Krona One</MenuItem>
    </Menu>
  </>
}

Fontfamily.propTypes = {
  menu: PropTypes.bool,
  setMenu: PropTypes.func,
  font: PropTypes.string,
  setFont: PropTypes.func
}

/** ================================= */

function App() {

  const classes = useStyle();

  let [content, setContent] = React.useState();
  let editorRef = React.useRef(null);

  React.useEffect(() => {
    editorRef.current.focus()
  })
  let [textStleMenu, setTextStyleMenu] = React.useState(false)

  let [editoButtons, setEditorButtons] = React.useState({
    bold: false,
    underline: false,
    italic: false
  })

  let [textStyle, setTextStyle] = React.useState('textStyle')

  // font style 
  let [fontmenu, setFontMenu] = React.useState(false)
  let [font, setFont] = React.useState('Roboto')

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} position="sticky" >
        <Toolbar>

          <Box display="flex" flexGrow={1}>
            <Box>
              <TextStyle
                menu={textStleMenu}
                setMenu={setTextStyleMenu}
                textStyle={textStyle}
                setTextStyle={setTextStyle}
              />
            </Box>

            <Box>
              <ButtonGroup>
                <Button variant={(editoButtons.bold) ? "contained" : null} onClick={() => {
                  document.execCommand('bold', true)
                  setEditorButtons({ ...editoButtons, bold: !editoButtons.bold })
                }}>
                  B
              </Button>
                <Button variant={(editoButtons.italic) ? "contained" : null} onClick={() => {
                  document.execCommand('italic', true)
                  setEditorButtons({ ...editoButtons, italic: !editoButtons.italic })
                }}>
                  I
              </Button>
                <Button variant={(editoButtons.underline) ? "contained" : null} onClick={() => {
                  document.execCommand('underline', false)
                  setEditorButtons({ ...editoButtons, underline: !editoButtons.underline })
                }}>
                  U
              </Button>
              </ButtonGroup>
            </Box>

            <Box>
              <Fontfamily
                menu={fontmenu}
                setMenu={setFontMenu}
                font={font}
                setFont={setFont}
              />
            </Box>
          </Box>

          <Box >

            <Button variant="outlined">
              Save
            </Button>

          </Box>
        </Toolbar>
      </AppBar>

      <Container>
        <Paper className={classes.paper}>
          <div className={classes.editor} ref={editorRef} contentEditable>

          </div>
        </Paper>
      </Container>
    </div>
  );
}

export default App;
